<template>
  <div id="app">
    <Main/>
    <v-footer>
      <v-container grid-list-xs,sm,md,lg,xl>
        <v-row>
          <v-col>
            <strong>МБУ "ЦДС ГПТ"</strong><br><br>
            <strong>ИНН 4345065415</strong><br><br>
            <strong>610004 г.Киров ул.Пятницкая 2А</strong><br><br>
            <strong>Официальный партнер компании Chat2Desk</strong>
          </v-col>
          <v-col>
            <strong>8 (8332) 48-59-94</strong><br><br>
            <strong>sec@cdsvyatka.com</strong><br><br>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import Main from './components/Main.vue'

export default {
  name: 'App',
  components: {
    Main
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
