<template>
  <v-container>
    <v-main row wrap>
      <v-layout row wrap align-center>
        <v-flex xs12 md6 lg3 class="mb-3 px-0">
          <v-card flat>
            <v-layout>
              <v-row>
                <v-col
                  v-for="n in chats"
                  :key="n.title"
                  cols="4"
                  align="center"
                >
                  <v-img
                    :src="`img/chats/${n.id}.png`"
                    :alt="`${n.title}`"
                    height="70px"
                    width="70px"
                    >
                  </v-img>
                </v-col>
              </v-row>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 md6 sm6 lg9 class="mb-3 mx-0">
          <v-row>
            <v-col>
              <v-flex xs12>
                <br><br>
                <h1>СОЗДАЕМ CALL-ЦЕНТРЫ НА МЕССЕНДЖЕРАХ</h1><br>
                <h3>Общайтесь там, где общаются Ваши клиенты</h3>
                <h3>Упростите знакомство</h3>
                <h3>Станьте ближе</h3>
                <br><br><br>
              </v-flex>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12 lg6 justify-center>
          <v-card flat>
            <v-card-text>
              WhatsApp, Viber, Telegram, ВКонтакте, Facebook и далее стали наиболее естественным для современного человека способом общения. Сейчас там все. И всегда на связи.<br>
              Люди все меньше общаются голосом, нам проще написать, чем позвонить. Переписка дает возможность общаться комфортно, в долгую, обдумывать свои мысли, не спешить с ответом, использовать фотографии, документы, ссылки.
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 lg6>
          <v-card flat>
            <v-card-text>
              <h2>СДЕЛАЙТЕ МЕССЕНДЖЕРЫ ИНСТРУМЕНТОМ ВАШЕГО БИЗНЕСА</h2><br>
              Представьте, покупатель на Вашем сайте.
              Он видит виджет с мессенджерами.
              Всего один клик и он в чате с Вами, в своем любимом мессенджере. Ваш чат теперь среди чатов его друзей!
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12 sm6 md6 lg6 justify-center>
          <v-card flat>
            <v-card-text>
              <h2>ПРЕИМУЩЕСТВА МЕССЕНДЖЕРОВ</h2><br>
              <v-row>
                <v-col>
                  <v-icon x-large>thumb_down</v-icon>
                  <ul align="left" class="bad">
                    <li>Говорить вслух по телефону не всегда удобно</li><br>
                    <li>Долгое прослушивание голосовых меню - раздражает</li><br>
                    <li>Переводы звонков на другого оператора и повторный рассказ проблемы - раздражает</li><br>
                    <li>Для поиска запрошенной информации нужно "висеть" на линии</li><br>
                    <li>Нельзя передать изображения и документы</li><br>
                    <li>Чат на сайте и диалог "живут" пока открыт сайт</li><br>
                    <li>Майлы не не читают</li><br>
                  </ul>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-icon x-large>thumb_up</v-icon>
                  <ul align="left" class="good">
                    <li>Сообщения из мессенджеров смотрят всегда</li><br>
                    <li>Привычный для клиента способ общения</li><br>
                    <li>Просто начать диалог</li><br>
                    <li>Общение может быть долгим, клиент всегда на связи</li><br>
                    <li>Можно передавать фото, изображения, документы, ссылки</li><br>
                    <li>Диалог не потеряется и его можно продолжить</li><br>
                    <li>Оператор не поменяется</li><br>
                    <li>Клиенту всегда можно написать</li>
                  </ul>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-img src="img/chatbot.jpg" height="400px"></v-img>
        </v-flex>
        <v-flex xs12 sm6 md6 lg6 justify-center>
          <v-card flat>
            <v-card-text>
              <h2>ВОЗМОЖНОСТИ</h2>
              <v-row>
                <v-col align="left">
                  <h4>ВИДЖЕТ</h4>
                  <span>Лего установить на Ваш сайт после регистрации в системе. </span><br><br>
                  <h4>ЛИЧНЫЙ КАБИНЕТ</h4>
                  <span>
                    Три уровня доступа. Графики работы. Конфеденциальность - оператор видит только свои чаты.
                    Группы операторов. Возможность переписки между операторами и передачи чата.
                  </span><br><br>
                  <h4>ШАБЛОНЫ</h4>
                  <span>
                    Шаблоны для быстрых ответов позволяют отвечать клиентам заранее подготовленным текстом и картинкой.
                    Шаблоны можно вызывать из списка или с помощью быстрой команды.
                  </span><br><br>
                  <h4>АВТООТВЕТ</h4>
                  <span>
                    Система может автоматически отвечать клиенту и попросить его ответить на вопросы,
                    которые ускорят работу оператора. Например, попросить ввести номер договора.
                  </span><br><br>
                  <h4>ОЦЕНКА КАЧЕСТВА</h4>
                  <span>
                    По окончании диалога клиент может оцеить качество работы оператора.
                  </span><br><br>
                  <h4>ТЭГИ</h4>
                  <span>
                    Клиентов и их обращения можно помечать тэгами, которые потом можно использовать для отбора и обработки.
                  </span><br><br>
                  <h4>МЕНЮ САМООБСЛУЖИВАНИЯ</h4>
                  <span>
                    Замена голосовому меню. Меню  самообслуживания позволяет  реализовать
                    подобные сценарии в мессенджерах и сразу переключить на нужного оператора и присвоить тэги.
                  </span><br><br>
                  <h4>ЧАТ БОТ</h4>
                  <span>
                    Может отвечать вместо оператора на типовые вопросы заданные человеческим языком.
                    Чат бот реагирует на ключевые слова в сообщении от клиента и отвечает текстом, фото, картинкой или файлом.
                  </span><br><br>
                  <h4>АГРЕГИРОВАННАЯ СТАТИСТИКА</h4>
                  <span>
                    Получайте максимум аналитики по работе Ваших операторов.
                  </span><br><br>
                  <h4>РАССЫЛКИ ПО КЛИЕНТАМ </h4>
                  <span>
                    Вы можете делать таргетированные рассылки по своей клиентской базе из системы.
                  </span><br><br>
                  <h4>ТУННЕЛИ ПРОДАЖ</h4>
                  <span>
                    Создавайте рассылки, где полезный контент чередуется с продающими сообщениями.
                    Вы сможете вести клиента от рекламного объявления в интернет до целевого действия.
                  </span><br><br>
                  <h4>РАССЫЛКА ИЗ EXCEL</h4>
                  <span>
                    Используя наш  специальный Excel-файл,  вы можете  делать массовые рассылки по
                    своей существующей базе клиентов, а также писать новым клиентам если вы знаете их номера телефонов.
                  </span><br><br>
                  <h4>VIBER БИЗНЕС</h4>
                  <span>
                    Позволяет делать рассылки клиентам, которые Вам ранее не писали, без боязни блокировки.
                    Доставляемость порядка 40%. Рассылать можно вручную с сайта, через Excel или API.
                  </span><br><br>
                  <h4>СКРИПТЫ</h4>
                  <span>
                    С помощью скриптов можно реализовать практически любые сценарии и функции.
                    Скрипты пишутся на простом языке Python. Их можем написать мы или Ваш разработчик.
                  </span><br><br>
                  <h4>API (application programming interface)</h4>
                  <span>
                    С помощью API возможна интеграция с Вашей системой, которая получит возможности приёма и
                    отправки сообщений, получения и редактирования информации о клиентах и много других возможностей.
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 sm6 md6 lg6>
          <v-card flat>
            <v-card-text>
              <h2>ЦЕНЫ</h2>
              <v-row>
                <v-col align="left">
                  <table width="100%">
                    <tr v-for="n in 8" :key="n">
                      <td>
                        <v-img
                          :src="`img/chats/${n}.png`"
                          :alt="`${n.title}`"
                          height="50px"
                          width="50px"
                        ></v-img>
                      </td>
                      <td>
                        <span>{{chats[n - 1].text}}</span>
                      </td>
                      <td>
                        <span>{{chats[n - 1].price}}</span>
                      </td>
                    </tr>
                  </table>
                  <br>
                  <h4 align="center" style="color:red">Получить полный прайс-лист и узнать условия Вы можете связавшись с нами в чате</h4>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 md6 lg6>
          <v-card flat>
            <v-card-text>
              <h2>ПОРЯДОК ДЕЙСТВИЙ</h2><br>
              <ul align="left" class="actions">
                <li>
                  <h3>
                    Связаться с нами в чате через любимый мессенджер через виджет
                    в правом нижнем углу. Изложить свои желания и мы их обсудим.
                  </h3>
                </li>
                <li>
                  <h3>
                    Мы Вас зарегистрируем в системе и подключим выбранные аккаунты.
                    Вы получите личный кабинет.
                  </h3>
                </li>
                <li>
                  <h3>Вы сами или с нашей помощью сделаете необходимые настройки системы.</h3>
                </li>
              </ul>
              <br><br><br>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-main>
  </v-container>
</template>

<script>
export default {
  name: 'HelloWorld',
  data: () => ({
    chats: [
      { 'id': '1', 'title': 'Whatsapp', 'text': 'WhatsApp (номерной)', 'price': '2500 руб.' },
      { 'id': '2', 'title': 'Viber', 'text': 'Viber public', 'price': '1000 руб.' },
      { 'id': '3', 'title': 'Vk', 'text': 'ВКонтакте', 'price': '1000 руб.' },
      { 'id': '4', 'title': 'Telegram', 'text': 'Telegram', 'price': '1000 руб.' },
      { 'id': '5', 'title': 'Facebook', 'text': 'Facebook', 'price': '1000 руб.' },
      { 'id': '6', 'title': 'Instagram', 'text': 'Instagram Direct и комментарии', 'price': '2500 руб.' },
      { 'id': '7', 'title': 'OK', 'text': 'Одноклассники', 'price': '1000 руб.' },
      { 'id': '8', 'title': 'Skype', 'text': 'Skype', 'price': '1000 руб.' },
      { 'id': '9', 'title': 'Twitter' },
      { 'id': '10', 'title': 'Wechat' },
      { 'id': '11', 'title': 'Messenger' },
      { 'id': '12', 'title': 'Mail' }
    ],
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul.bad {
  list-style-type: '❌';
  padding: 0;
  margin-left: 10px;
}
ul.good {
  list-style-type: '✔';
  padding: 0;
  margin-left: 10px;
}
ul.actions {
  list-style-type: decimal;
  padding: 5px;
}
td {
  padding: 10px;
}
a {
  color: #42b983;
}
</style>
